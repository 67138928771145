<template>
  <div id="mission-onelakh">
    <v-card class="text-center">
      <v-card-title>
        <h2>{{ PageTitle }} - {{ rows.ZoneName }} - {{ rows.LomName }}</h2>
        <v-spacer />
        <v-btn
          @click.prevent="refreshPageData"
          color="#a4c639"
          min-width="92"
          rounded
          mediums
          class="font-size-h6 px-3 py-3 ml-3 white--text"
        >
          Refresh
        </v-btn>
      </v-card-title>
      <v-row>
        <v-col cols="12" lg="12" md="12" sm="12" align="center">
          <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
            <b-spinner
              variant="primary"
              type="grow"
              label="Loading..."
            ></b-spinner>
          </div>
          <v-overlay
            v-model="LoadingFlag"
            contained
            class="align-center justify-center"
          ></v-overlay>
        </v-col>
      </v-row>
      <v-card-text>
        <v-row wrap align="center">
          <v-col cols="12" lg="4" md="6" sm="6" v-if="!rows.Phase1Flag">
            <div id="card-body">
              <v-card id="card" color="#f9844a">
                <v-card-text>
                  <h2 class="white--text">
                    Phase
                    <v-icon icon size="35px" color="white">
                      mdi mdi-numeric-1-circle
                    </v-icon>
                  </h2>
                  <hr />
                  <br />
                  <span class="text-h5 white--text"> Committee members</span
                  ><br /><br />
                  <span class="text-h5 white--text"> Status:</span>&nbsp;&nbsp;
                  &nbsp;
                  <v-chip
                    :color="getActiveStatusColor(rows.Phase1StatusTxt)"
                    draggable
                    dark
                    >{{ rows.Phase1StatusTxt }}</v-chip
                  ><v-tooltip bottom v-if="rows.Phase1CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="rows.Phase1CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span> </v-tooltip
                  ><br /><br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase1DeadlineDateTxt"
                  >
                    DeadLine Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase1DeadlineDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase1DeadlineDateTxt }}</span
                  ><br /><br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase1CompletedDateTxt"
                  >
                    Completed Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase1CompletedDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase1CompletedDateTxt }}</span
                  >
                  <br />
                  <router-link to="/mission-one-lakh/committee/add-member">
                    <v-btn
                      v-if="rows.Phase1ViewFlag"
                      id="add-member"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      View
                    </v-btn> </router-link
                  >&nbsp;&nbsp;&nbsp;
                  <router-link to="/mission-one-lakh/committee/add-member">
                    <v-btn
                      style="margin-top: 18px"
                      v-if="!rows.Phase1Flag"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      Start
                    </v-btn>
                  </router-link>
                  <br />
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="6">
            <div id="card-body">
              <v-card id="card" color="#b18fcf">
                <v-card-text>
                  <h2 class="white--text">Mission One Lakh</h2>
                  <hr />
                  <br />
                  <span class="text-h5 white--text">
                    List of Potential Members</span
                  >
                  <span class="text-h5 white--text" v-if="false"> Status:</span
                  >&nbsp;&nbsp; &nbsp;
                  <v-chip
                    v-if="false"
                    :color="getActiveStatusColor(rows.Phase2StatusTxt)"
                    draggable
                    dark
                    >{{ rows.Phase2StatusTxt }}</v-chip
                  ><v-tooltip bottom v-if="rows.Phase2CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="rows.Phase2CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span> </v-tooltip
                  ><br />
                  <br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase2DeadlineDateTxt"
                  >
                    DeadLine Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase2DeadlineDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase2DeadlineDateTxt }}</span
                  ><br /><br />
                  <v-row v-if="false">
                    <span
                      class="text-h5 white--text"
                      v-if="rows.Phase2CompletedDateTxt"
                    >
                      Completed Date:</span
                    >&nbsp;&nbsp; &nbsp;
                    <span
                      v-if="rows.Phase2CompletedDateTxt"
                      class="text-h5 white--text"
                      >{{ rows.Phase2CompletedDateTxt }}</span
                    ></v-row
                  >
                  <br />
                  <router-link to="/mission-one-lakh/member-list">
                    <v-btn
                      id="add-member"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      Enter
                    </v-btn> </router-link
                  >&nbsp;&nbsp;&nbsp;
                  <router-link
                    to="/mission-one-lakh/member-list"
                    v-if="!rows.Phase2Flag"
                  >
                    <v-btn
                      style="margin-top: 18px"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      Start
                    </v-btn>
                  </router-link>
                  <br />
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="6" v-if="rows.Phase3Flag">
            <div id="card-body">
              <v-card id="card" color="#90be6d">
                <v-card-text>
                  <h2 class="white--text">
                    Phase
                    <v-icon icon size="35px" color="white">
                      mdi mdi-numeric-3-circle
                    </v-icon>
                  </h2>
                  <hr />
                  <br />
                  <span class="text-h5 white--text">
                    Potential Members visited / invited</span
                  >
                  <br /><br />
                  <span class="text-h5 white--text"> Status:</span>&nbsp;&nbsp;
                  &nbsp;
                  <v-chip
                    :color="getActiveStatusColor(rows.Phase3StatusTxt)"
                    draggable
                    dark
                    >{{ rows.Phase3StatusTxt }}</v-chip
                  ><v-tooltip bottom v-if="rows.Phase3CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="rows.Phase3CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span> </v-tooltip
                  ><br />
                  <br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase3DeadlineDateTxt"
                  >
                    DeadLine Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase3DeadlineDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase3DeadlineDateTxt }}</span
                  ><br /><br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase3CompletedDateTxt"
                  >
                    Completed Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase3CompletedDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase3CompletedDateTxt }}</span
                  >
                  <br /><br />
                  <router-link to="/mission-one-lakh/invited/member-list">
                    <v-btn
                      v-if="rows.Phase3ViewFlag"
                      id="add-member1"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      View
                    </v-btn> </router-link
                  >&nbsp;&nbsp;&nbsp;
                  <router-link to="/mission-one-lakh/invited/member-list">
                    <v-btn
                      v-if="!rows.Phase3Flag"
                      style="margin-top: 5px"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      Start
                    </v-btn>
                  </router-link>
                  <br />
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>
        <v-row wrap align="center" v-if="rows.Phase5Flag">
          <v-col cols="12" lg="4" md="6" sm="6" v-if="CardFlag4">
            <div id="card-body">
              <v-card id="card" color="#43aa8b">
                <v-card-text>
                  <h2 class="white--text">
                    Phase
                    <v-icon icon size="35px" color="white">
                      mdi mdi-numeric-4-circle
                    </v-icon>
                  </h2>
                  <hr />
                  <br />
                  <span class="text-h5 white--text">
                    Potential Members re-visited / re-invited</span
                  >
                  <br /><br />
                  <span class="text-h5 white--text"> Status:</span>&nbsp;&nbsp;
                  &nbsp;
                  <v-chip
                    :color="getActiveStatusColor(rows.Phase4StatusTxt)"
                    draggable
                    dark
                    >{{ rows.Phase4StatusTxt }}</v-chip
                  >
                  <v-tooltip bottom v-if="rows.Phase4CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="rows.Phase4CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span> </v-tooltip
                  ><br />
                  <br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase4DeadlineDateTxt"
                  >
                    DeadLine Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase4DeadlineDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase4DeadlineDateTxt }}</span
                  ><br /><br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase4CompletedDateTxt"
                  >
                    Completed Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase4CompletedDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase4CompletedDateTxt }}</span
                  >
                  <br /><br />
                  <router-link to="/mission-one-lakh/re-invited/member-list">
                    <v-btn
                      v-if="rows.Phase4ViewFlag"
                      id="add-member1"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      View
                    </v-btn>
                  </router-link>
                  &nbsp;&nbsp;&nbsp;
                  <router-link to="/mission-one-lakh/re-invited/member-list">
                    <v-btn
                      v-if="!rows.Phase4Flag"
                      style="margin-top: 5px"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      Start
                    </v-btn>
                  </router-link>
                  <br />
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="6">
            <div id="card-body">
              <v-card id="card" color="#4d908e">
                <v-card-text>
                  <h2 class="white--text">
                    Phase
                    <v-icon icon size="35px" color="white">
                      mdi mdi-numeric-5-circle
                    </v-icon>
                  </h2>
                  <hr />
                  <br />
                  <span class="text-h5 white--text">
                    Orientation Program for Potential Members</span
                  >
                  <br /><br />
                  <span class="text-h5 white--text"> Status:</span>&nbsp;&nbsp;
                  &nbsp;
                  <v-chip
                    :color="getActiveStatusColor(rows.Phase5StatusTxt)"
                    draggable
                    dark
                    >{{ rows.Phase5StatusTxt }}</v-chip
                  >
                  <v-tooltip bottom v-if="rows.Phase5CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="rows.Phase5CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span> </v-tooltip
                  ><br />
                  <br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase5DeadlineDateTxt"
                  >
                    DeadLine Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase5DeadlineDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase5DeadlineDateTxt }}</span
                  ><br /><br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase5CompletedDateTxt"
                  >
                    Completed Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase5CompletedDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase5CompletedDateTxt }}</span
                  >
                  <br /><br />
                  <router-link to="/mission-one-lakh/attended/member-list">
                    <v-btn
                      v-if="rows.Phase5ViewFlag"
                      style="margin-left: 1px"
                      id="add-member1"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      View
                    </v-btn> </router-link
                  >&nbsp;&nbsp;&nbsp;
                  <router-link to="/mission-one-lakh/attended/member-list">
                    <v-btn
                      v-if="!rows.Phase5Flag"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      Start
                    </v-btn>
                  </router-link>
                  <br />
                </v-card-text>
              </v-card>
            </div>
          </v-col>
          <v-col cols="12" lg="4" md="6" sm="6">
            <div id="card-body">
              <v-card id="card" color="#577590">
                <v-card-text>
                  <h2 class="white--text">
                    Phase
                    <v-icon icon size="35px" color="white">
                      mdi mdi-numeric-6-circle
                    </v-icon>
                  </h2>
                  <hr />
                  <br />
                  <span class="text-h5 white--text">
                    Induct Potential Members by paying subscription</span
                  >
                  <br /><br />
                  <span class="text-h5 white--text"> Status:</span>&nbsp;&nbsp;
                  &nbsp;
                  <v-chip
                    :color="getActiveStatusColor(rows.Phase6StatusTxt)"
                    draggable
                    dark
                    >{{ rows.Phase6StatusTxt }}</v-chip
                  >
                  <v-tooltip bottom v-if="rows.Phase6CertificatePath != ''">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :href="rows.Phase6CertificatePath"
                        target="_blank"
                        class="mx-2"
                        icon
                        dark
                        small
                        color="white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-download</v-icon>
                      </v-btn>
                    </template>
                    <span>Download</span> </v-tooltip
                  ><br />
                  <br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase6DeadlineDateTxt"
                  >
                    DeadLine Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase6DeadlineDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase6DeadlineDateTxt }}</span
                  ><br /><br />
                  <span
                    class="text-h5 white--text"
                    v-if="rows.Phase6CompletedDateTxt"
                  >
                    Completed Date:</span
                  >&nbsp;&nbsp; &nbsp;
                  <span
                    v-if="rows.Phase6CompletedDateTxt"
                    class="text-h5 white--text"
                    >{{ rows.Phase6CompletedDateTxt }}</span
                  >
                  <br /><br />
                  <router-link to="/mission-one-lakh/join-jci-member-list">
                    <v-btn
                      v-if="rows.Phase6ViewFlag"
                      style="margin-left: 1px"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      View
                    </v-btn> </router-link
                  >&nbsp;&nbsp;&nbsp;
                  <router-link to="/mission-one-lakh/join-jci-member-list">
                    <v-btn
                      v-if="!rows.Phase6Flag"
                      rounded
                      color="#000"
                      class="font-size-h6 white--text"
                    >
                      Start
                    </v-btn>
                  </router-link>
                  <br />
                </v-card-text>
              </v-card>
            </div>
          </v-col>
        </v-row>

        <br />
      </v-card-text>
    </v-card>
  </div>
</template>
<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  data() {
    return {
      CardFlag1: true,
      CardFlag2: true,
      CardFlag3: true,
      CardFlag4: true,
      CardFlag5: true,
      CardFlag6: true,

      addRecordPrompt: false,
      LoadingFlag: false,
      PageInfo: {},
      rows: {
         "Phase1Flag": true,
      },
      PageTitle: "",
      PageDescription: "",
      JciYearCode: "",
      YearName: "",
      NextYearCode: "",
      NextYearName: "",
    };
  },
  computed: {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = NextJciYearId;

        var LomId = this.$session.get("LomId");
        LomId = LomId == (null || undefined) ? "" : LomId;
        this.LomCode = LomId;

        this.pageData();
      }
    },
    rows: function () {
      console.log("watch rows");
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "mission_one_lakh",
        Action: "dashboard",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
      this.getTableRecords();
    },
    pageData() {
      console.log("pageData called");
    },
    getTableRecords() {
      console.log("getTableRecords called");

      var YearCode = this.JciYearCode;
      console.log("YearCode=" + YearCode);

      // this.rows = [];

      var server_url = companyConfig.apiURL;
      var token = this.$session.get("token");
      token = token == (null || undefined) ? 0 : token;
      var add_url = server_url + "api/mol-dashboard";
      var upload = {
        UserInterface: 1,
      };
      console.log("upload=" + JSON.stringify(upload));
      console.log(
        "server_url=" + server_url + ", token=" + token + ", add_url=" + add_url
      );

      //this.rows = [];
      this.SearchFlag = true;
      this.LoadingFlag = true;
      this.OverlayLoadingFlag = true;

      const thisIns = this;
      var output = "";
      var records = "";
      var flag = 0;

      this.$http({
        url: add_url,
        method: "POST",
        data: upload,
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: function (progressEvent) {
          this.uploadPercentage = parseInt(
            Math.round((progressEvent.loaded / progressEvent.total) * 100)
          );
        }.bind(this),
      })
        .then(function (response) {
          console.log("response=" + JSON.stringify(response));

          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
          thisIns.StartupLoadingFlag = false;
          thisIns.OverlayLoadingFlag = false;

          output = response.data.output;
          flag = response.data.flag;
          output = response.data.output;
          records = response.data.records;
          console.log(
            "output=" + output + ", flag=" + flag + ", records=" + records
          );

          if (flag == 1) {
            thisIns.rows = records;
            thisIns.toast("success", output);
          } else {
            thisIns.toast("error", output);
          }
        })
        .catch(function (error) {
          console.log("error=" + error);
          thisIns.toast("error", error);
          thisIns.SearchFlag = false;
          thisIns.LoadingFlag = false;
        });
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>

<style lang="scss">
#mission-onelakh {
  .v-btn--floating {
    position: relative;
  }

  .v-speed-dial--bottom {
    bottom: 12%;
  }

  .v-speed-dial--right {
    right: 5%;
  }

  .notes-title {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }

  .notes-content {
    font-size: 20px;
    font-weight: bold;
    line-height: 2em;
  }

  #card-body {
    position: relative;
    height: 380px;
    box-shadow: inset 5px 5px 5px rgba(0, 0, 0, 0.2),
      inset -5px -5px 15px #094aee1a, 5px 5px 15px,
      -5px -5px 15px rgba(150, 5, 5, 0.1);
    border-radius: 15px;
  }

  #card {
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
  }

  #add-member {
    margin-top: 20px;
    margin-left: 50px;
  }
  #add-member1 {
    margin-top: 1px;
    margin-left: 50px;
  }
}
</style>